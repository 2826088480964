import { useLocation } from '@reach/router';
import { parse } from 'qs';
import { isBrowser } from '../utils/utils';

export enum LoginSource {
  Funnel = 'FUNNEL',
  Register = 'REGISTER',
}

interface Authenticate {
  token: string;
  authMethod: 'token' | 'session' | null;
  isLoggedIn: boolean;
  doLogin: () => void;
  doLogout: () => void;
  customerId: string | number | false | null;
  shareableRegister?: boolean;
}

function useAuthenticate(): Authenticate {
  const { search } = useLocation();

  function doLogin(): void {
    if (isBrowser()) localStorage.setItem('isLoggedIn', 'true');
  }

  function doLogout(): void {
    if (isBrowser()) {
      localStorage.clear();
      sessionStorage.clear();
    }
  }

  const queryObject = parse(search, { ignoreQueryPrefix: true });
  const token =
    queryObject?.token || (isBrowser() && localStorage.getItem('token'));
  const isLoggedIn =
    isBrowser() && localStorage.getItem('isLoggedIn') === 'true';
  const shareableRegister =
    isBrowser() && localStorage.getItem('shareableRegister') === 'true';
  const customerId = isBrowser() && localStorage.getItem('customerId');

  if (isBrowser() && token !== localStorage.getItem('token'))
    localStorage.setItem('token', token);

  const authMethod = isLoggedIn ? 'session' : token ? 'token' : null;

  return {
    token,
    isLoggedIn,
    authMethod,
    doLogin,
    doLogout,
    shareableRegister,
    customerId,
  };
}

export default useAuthenticate;
