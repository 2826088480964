import { defineMessages } from 'react-intl';

export default defineMessages({
  homepageTitle: {
    id: 'cc4.homepage.title',
    defaultMessage: 'Flight Delayed',
  },
  searchFlightsFormHeader: {
    id: 'cc4.form.search-flights.header',
    defaultMessage: 'Tell us about your flight',
    description: 'First header showed at the beginning of process',
  },
  searchFlightsFormDescription: {
    id: 'cc4.form.search-flights.description',
    defaultMessage: 'Did you have a direct flight or with stopovers?',
    description: 'Description below header showed at the beginning of process',
  },
  or: {
    id: 'cc4.form.search-flights.seperator-text',
    defaultMessage: 'or',
  },
  connectionTypeLabelDirect: {
    id: 'cc4.form.search-flights.field.label.connection-type.direct',
    defaultMessage: 'Direct flight',
  },
  connectionTypeLabelConnecting: {
    id: 'cc4.form.search-flights.field.label.connection-type.connecting-flight',
    defaultMessage: 'Connecting flight',
  },
  speedUpProcessWithFlightCode: {
    id: 'cc4.form.search-flights.speed-up-process-link',
    defaultMessage: 'Speed-up the process {cta}',
  },
  speedUpProcessWithFlightCodeCTA: {
    id: 'cc4.form.search-flights.speed-up-process-with-flight-code-cta',
    defaultMessage: 'entering the flight number',
  },

  iDontKnowFlightNumber: {
    id: 'cc4.form.search-flights.i-dont-know-flight-number-link',
    defaultMessage: "I don't know flight number",
  },
  flightDateLabel: {
    id: 'cc4.form.search-flights.field.label.flight-date',
    defaultMessage: 'Flight date',
  },
  flightNumberLabel: {
    id: 'cc4.form.search-flights.field.label.flight-code',
    defaultMessage: 'Flight number',
  },
  flightNumberLabelAbbr: {
    id: 'cc4.form.search-flights.field.label.flight-code-abbreviation',
    defaultMessage: 'Flight no.',
  },
  flightDateLabelAbbr: {
    id: 'cc4.form.search-flights.flight-date-abbreviation',
    defaultMessage: 'Date',
  },
  flightCodePlaceholder: {
    id: 'cc4.form.search-flights.field.placeholder.flight-code',
    defaultMessage: 'e.g. KL875',
  },
  selectFlightButton: {
    id: 'cc4.form.search-flights.select-flight-button-text',
    defaultMessage: 'Select',
  },
  searchFlightsNoResults: {
    id: 'cc4.form.search-flights.no-results-alert',
    defaultMessage:
      'We have not found this flight code in our database. Please provide your flight data again -- our team will check it manually.',
  },
  searchFlightsNoResultsFirst: {
    id: 'cc4.form.search-flights.no-results-first-alert',
    defaultMessage:
      'We have not found your flight in our database. Please provide the flight code instead.',
  },
  selectYourFlight: {
    id: 'cc4.form.search-flights.multiple-flight-results-header',
    defaultMessage: 'Select your flight',
    description: 'Header shown when multiple flights found',
  },
  oneFlightResultHeader: {
    id: 'cc4.form.search-flights.one-flight-result-header',
    defaultMessage: 'Your flight details',
    description: 'Header shown when only one flight is found',
  },
  myFlightIsNotDisplayed: {
    id: 'cc4.form.search-flights.my-flight-is-not-displayed',
    defaultMessage: 'My flight is not displayed',
    description: 'Text shown under flights results',
  },
  provideFlightCode: {
    id: 'cc4.form.search-flights.provide-flight-code-button-text',
    defaultMessage: 'Provide a flight code',
  },
  enterFlightManually: {
    id: 'cc4.form.search-flights.enter-flight-manually',
    defaultMessage: 'Enter flight manually',
  },
  continue: {
    id: 'cc4.form.search-flights.primary-button-text',
    defaultMessage: 'Continue',
    description:
      'Main CTA button text on first page when flight is found or airports selected on connecting flight',
  },
  back: {
    id: 'cc4.form.search-flights.secondary-button-text',
    defaultMessage: 'Back',
  },
  continueManualFlight: {
    id: 'cc4.form.search-flights.continue-manual-add',
    defaultMessage: 'Continue',
  },
  continueConnectingFlights: {
    id: 'cc4.form.search-flights.primary-button-text-connecting-flight',
    defaultMessage: 'Continue',
    description: 'Main CTA button text on first page when flight is found',
  },
  continueAlternativeFlight: {
    id: 'cc4.form.search-flights.primary-button-text-alternative-flight',
    defaultMessage: 'Continue',
  },
  asideText: {
    id: 'cc4.form.search-flights.aside-text',
    defaultMessage: `Provide us with basic details regarding your flight and see how much flight compensation you are entitled to!
    Checking your flight compensation and flight refund eligibility with our Claim Calculator is completely free of charge.`,
  },
  connectingFlightAsideText: {
    id: 'cc4.form.search-flights.connecting-flight-aside-text',
    defaultMessage:
      'Your compensation may be higher in case of missed connection. Tell us your full itinerary',
  },
  alternativeFlightAsideText: {
    id: 'cc4.form.alternative-flight.aside-text',
    defaultMessage:
      'We have helped millions of passengers with their disrupted flight for over 9 years',
  },
  connectingFlightSubmitBack: {
    id: 'cc4.form.search-flights.connecting-flight-back',
    defaultMessage: 'Back',
  },
  alternativeFlightSubmitBack: {
    id: 'cc4.form.alternative-flight.back',
    defaultMessage: 'Back',
  },
  connectingFlightHeader: {
    id: 'cc4.form.search-flights.connecting-flight-header',
    defaultMessage: `{index, plural,
      =0 {Enter the first flight of your scheduled trip}
      one {Enter your connecting flight}
      other {Enter your next connecting flight}
    }`,
  },
  alternativeFlightHeader: {
    id: 'cc4.form.alternative-flight.header',
    defaultMessage: 'Enter replacement flight details',
  },
  alternativeFlightSubtitle: {
    id: 'cc4.form.alternative-flight.subtitle',
    defaultMessage: `Replacement flight {flightIndex}`,
  },
  alternativeFlightQuestionLabel: {
    id: 'cc4.form.alternative-flight.question-label',
    defaultMessage: 'Were you offered a replacement flight?',
  },
  alternativeFlightQuestionExtraDescription: {
    id: 'cc4.form.alternative-flight.question-extra-description',
    defaultMessage:
      'The airline might deny compensation if the details of your alternative flight are not included in the claim.',
  },
  alternativeFlightOptionYes: {
    id: 'cc4.form.alternative-flight.option-yes',
    defaultMessage: 'Yes',
  },
  alternativeFlightOptionNo: {
    id: 'cc4.form.alternative-flight.option-no',
    defaultMessage: 'No',
  },
  alternativeFlightSearchLabel: {
    id: 'cc4.form.alternative-flight.search-label',
    defaultMessage: 'Submit the details of the alternative flight',
  },
  alternativeFlightCountLabel: {
    id: 'cc4.form.alternative-flight.count-label',
    defaultMessage: 'How many replacement flights did you have?',
  },
  validationFlightDateMax: {
    id: 'cc4.form.search-flights.date.max',
    defaultMessage: 'Date must be earlier than +1 year',
  },
  validationFlightDateMin: {
    id: 'cc4.form.search-flights.date.min',
    defaultMessage: 'Date must be later',
  },
  connectigFlightFormHeader: {
    id: 'cc4.form.search-flights.connecting.header',
    defaultMessage: 'Enter connecting flight details',
  },
  replacementFlightFormHeader: {
    id: 'cc4.form.search-flights.replacement.header',
    defaultMessage: 'Enter replacement flight details',
  },
  selectConnectionType: {
    id: 'cc4.form.search-flights.field.error.message',
    defaultMessage:
      'Please pick whether you had a direct flight or connecting flights.',
  },
  returnFlightType: {
    id: 'cc4.client-account.subscription-return-flight-checkbox',
    defaultMessage: 'I also have a return flight',
  },
});
