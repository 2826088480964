import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import useDataLayer from '../../hooks/useDataLayer';

const wrapperClasses = {
  default: 'mb-15',
  border:
    'min-w-radioSmall sm:min-w-radio min-h-radio border border-gray-200 rounded cursor-pointer',
  mobile: 'shadow-tile rounded-md mb-20',
  active: 'shadow-tile-primary bg-primary-05',
};

const RadioButton = props => {
  const {
    component: Component,
    className,
    field,
    value,
    label,
    hasError,
    row,
    index,
    form,
    border,
    callback,
    dataLayerPush,
    width = 'auto',
  } = props;
  const { formatMessage } = useIntl();
  const id = `input-radio-${field.name}-${value}`;
  const inputEl = useRef(null);
  const checked = field.value === value;
  const [pushEvent] = useDataLayer();
  function handleChange() {
    const inputValue = inputEl?.current?.value;
    form?.setFieldTouched(field?.name, true);
    form?.setFieldValue(field?.name, inputValue);
    callback && callback(inputValue, form);
    if (dataLayerPush) {
      pushEvent({
        eventLabel: `${field?.name} ${inputValue}`,
        ...dataLayerPush,
      });
    }
  }

  function handleLabelClick(event) {
    event.stopPropagation();
  }

  return (
    <Component
      onClick={handleChange}
      style={{ width }}
      className={clsx(
        'flex items-center',
        [
          { [wrapperClasses['default']]: !row && !isMobile },
          { [wrapperClasses['mobile']]: isMobile && !row },
          { [wrapperClasses['active']]: checked && isMobile && !row },
        ],
        {
          [wrapperClasses.border]: border && row,
          'rounded-tr-none rounded-br-none': index === 0 && row && border,
          'border-l-0 rounded-tl-none rounded-bl-none':
            index === 1 && row && border,
        },
        props.field?.name || undefined,
        className
      )}
    >
      <input
        id={id}
        ref={inputEl}
        type="radio"
        className={clsx(
          'form-radio',
          { 'ml-18': row },
          { 'ml-10': isMobile },
          className
        )}
        checked={checked}
        aria-invalid={hasError}
        {...props.field}
        value={value}
      />
      {label && (
        <label
          className={clsx({
            'ml-8': row,
            'ml-10': !row,
            'py-13 pr-10': isMobile && !row,
          })}
          htmlFor={id}
          onClick={handleLabelClick}
        >
          {label?.id ? formatMessage(label) : label}
        </label>
      )}
    </Component>
  );
};

RadioButton.defaultProps = {
  component: 'div',
  row: false,
};

RadioButton.propTypes = {
  border: PropTypes.bool,
  callback: PropTypes.func,
  className: PropTypes.string,
  component: PropTypes.string,
  dataLayerPush: PropTypes.bool,
  field: PropTypes.object,
  form: PropTypes.object,
  hasError: PropTypes.bool,
  index: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  row: PropTypes.bool,
  value: PropTypes.string,
  width: PropTypes.string,
};

export default RadioButton;
