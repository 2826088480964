import React, { useState } from 'react';

import { useLocalStorageState } from '../../hooks/useLocalStorageState';

import SearchByCode from './SearchByCode';
import SearchByAirports from './SearchByAirports';
import EnterFlightManually from './EnterFlightManually';
import { FlightType } from '../../models/CustomerFlightModel';
import { isEmpty } from 'lodash';

export enum FlightDisruption {
  Delayed = 'delayed',
  Canceled = 'canceled',
  Rebooked = 'rebooked',
  Diverted = 'diverted',
  Denied = 'denied',
  OnTime = 'on_time',
  MissedConnection = 'missed_connection',
}

export interface CartFlight {
  type: FlightType;
  flight_code: string;
  flight_date: string;
  flight_status: FlightDisruption;
  airline_code: string;
  departure_airport: string;
  arrival_airport: string;
}

export interface FunnelFlight {
  type: FlightType;
  flight_date: string;
  airline_id: string;
  departure_time: string;
  arrival_time: string;
  departure_airport_id: string;
  arrival_airport_id: string;
}

interface InitialValues {
  flightNumber: string;
  flightDate: string;
}

export interface FlightSearchFormProps {
  flightType: FlightType;
  flightIndex?: number;
  navigateNext: () => void;
  navigateBack?: () => void;
  step: string;
  fixedAirports?: [string, string];
  initialValues?: InitialValues | {};
}

export default function FlightSearch({
  fixedAirports,
  initialValues,
  ...props
}: FlightSearchFormProps) {
  /**
   * Flight search method switch
   */
  const [isFlightCodeShown, setIsFlightCodeShown] = useLocalStorageState(
    `${props.step}-flight-code-search`,
    false
  );
  const [showManualFlight, setShowManualFlight] = useState(false);

  function showFlightCodeField() {
    setIsFlightCodeShown(true);
  }
  function hideFlightCodeField() {
    setIsFlightCodeShown(false);
  }

  function showManualFlightForm() {
    setShowManualFlight(true);
  }

  /**
   * Render
   */
  if (showManualFlight) {
    return <EnterFlightManually {...props} />;
  }

  if (isFlightCodeShown || !isEmpty(initialValues)) {
    return (
      <SearchByCode
        showFlightCodeField={showFlightCodeField}
        hideFlightCodeField={hideFlightCodeField}
        initialValues={initialValues}
        showManualFlightForm={showManualFlightForm}
        {...props}
      />
    );
  }

  if (!isFlightCodeShown) {
    return (
      <SearchByAirports
        isFlightCodeShown={isFlightCodeShown}
        showFlightCodeField={showFlightCodeField}
        fixedAirports={fixedAirports}
        showManualFlightForm={showManualFlightForm}
        {...props}
      />
    );
  }

  return null;
}
