import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

// Components
import InputGroupWrapper from './InputGroup';
import translatable from '../../utils/propTypes/translatable';

const Checkbox = ({
  name,
  className,
  checkboxLabel,
  setFieldValue,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(true);

  const toggleNewsletter = () => setIsChecked(!isChecked);

  const intl = useIntl();
  return (
    <div className="inline-flex items-center mr-10">
      <input
        type="checkbox"
        name={name}
        id={name}
        className={`form-checkbox ${className}`}
        aria-invalid={props.hasError}
        checked={name === 'newsletter' ? isChecked : props.field.value}
        onChange={
          name === 'newsletter'
            ? function() {
                setFieldValue('newsletter', !isChecked);
              }
            : null
        }
        onClick={name === 'newsletter' && toggleNewsletter}
        {...props.field}
      />
      <label htmlFor={name} className="ml-15 text-black">
        {typeof checkboxLabel === 'object' &&
        !React.isValidElement(checkboxLabel)
          ? intl.formatMessage(checkboxLabel)
          : React.isValidElement(checkboxLabel)
          ? checkboxLabel
          : checkboxLabel}{' '}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  checkboxLabel: PropTypes.oneOfType([translatable, PropTypes.element]),
  className: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setFieldValue: PropTypes.func,
  type: PropTypes.string,
  wrapperLabel: PropTypes.string,
};

// eslint-disable-next-line react/no-multi-comp
const CheckboxField = ({ label, ...props }) => (
  /**
   *  (Hack) label is main label for Input, renaming it to checkboxLabel will just
   *  display it next to checkbox
   */
  <InputGroupWrapper
    {...props}
    label={null}
    checkboxLabel={label}
    component={Checkbox}
  />
);

CheckboxField.propTypes = {
  label: PropTypes.oneOfType([translatable, PropTypes.element]),
};

export default CheckboxField;
