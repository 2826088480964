import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import config from '../../config';

import '../../utils/yupIntlSetup';
import * as yup from 'yup';
import { useJsonApi } from '../../services/GlobalStore/GlobalStore';
import Card from '../../components/Card';
import messages from './messages';
import FormikPersist from '../../components/fields/FormikPersist';
import StepNavigation from '../../components/StepNavigation';
import Input from '../../components/fields/Input';
import Count from '../../components/fields/Count';
import { useGlobalState } from '../../services/GlobalStore/GlobalStore';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import Container from '../../components/Container';
import Aside from '../../components/Aside';
import Icon from '../../components/Icon';

import moneyIcon from '../../assets/icons/money-icon.svg';
import Main from '../../components/Main';
import { jsonApiAxios, useHandleError } from '../../services/api';
import Seo from '../../components/seo';
import { steps, useTranslatedNavigate } from '../../utils/routes';
import routes from '../../messages/routes';
import { PRODUCT_TYPE } from '../../services/appLocalStorage';
import StepObserver from '../../components/StepObserver';

import Checkbox from '../../components/fields/Checkbox';
import {
  flightDisruptionMissing,
  shouldChooseAlternativeFlight,
} from '../Itinerary';
import { getDataMeta } from '../../services/resources/claims';
import PaxIcon from '../../assets/icons/passenger-icon.inline.svg';
import { LoginSource } from '../../hooks/useAuthenticate';
import InfoOverlay from '../../components/InfoOverlay';
import TrustBox from '../../components/TrustBox';
import DuplicateClaimAlert from './DuplicateClaimAlert';
import SocialProof from '../../components/SocialProof';
import SocialProofMobile from '../../components/SocialProof/Mobile';
import RadioGroup from '../../components/fields/RadioGroup';
import NativeSelect from '../../components/fields/NativeSelect';

export async function createCart(data) {
  return jsonApiAxios.post(
    '/calculatorCarts?include[0]=placeOfJurisdiction&include[1]=lawyer',
    { ...data, newsletter: Number(!!data.newsletter) },
    {
      schema: {
        attributes: [
          'form_data',
          'passengers',
          'email',
          'product_type',
          'newsletter',
        ],
        dataMeta: getDataMeta,
      },
      type: 'calculatorCarts',
    }
  );
}

const validationSchema = yup.object().shape({
  passengers: yup.number().required(),
  email: yup
    .string()
    .email()
    .required(),
  visa_card_type: yup.string().when('visa', isVisa => {
    return !config.isVisaSite || (config.isVisaSite && isVisa === 'no')
      ? yup.string().notRequired()
      : yup.string().required();
  }),
});

/**
 * Sets also product type if any flight is cancelled and between COVID dates
 * @param customerFlights
 * @param alternativeFlights
 * @param flightDisruptions
 * @param values
 * @returns {{form_data : *, product_type : *}}
 */
export function gatherFlightData({
  customerFlights,
  alternativeFlights = [],
  flightDisruptions,
  values,
}) {
  let commonData = flight => ({
    flight_date: flight.flight_date || flight.departure_date,
    airline_code: flight.airline_code,
    departure_airport: flight.departure_airport,
    arrival_airport: flight.arrival_airport,
    flight_code:
      flight.flight_code || flight.airline_code + flight.flight_number,
  });

  let customerFlightsData = customerFlights.map((flight, index) => ({
    flight_status: flightDisruptions[index],
    type: 'booked',
    ...commonData(flight),
  }));

  let alternativeFlightsData = alternativeFlights.map(flight => ({
    flight_status: null,
    type: 'alternative',
    ...commonData(flight),
  }));

  return {
    form_data: customerFlightsData.concat(alternativeFlightsData),
    ...values,
  };
}

/**
 * Clears all forms from Part II - we must do it when user submits form with
 * email. Might be you changed it on purpose, then you don't want data from
 * previous claim.
 */
export const clearPart2Data = () => {
  localStorage.removeItem('yo:customerId');
  localStorage.removeItem('yo:shouldAuthenticate');
  localStorage.removeItem('yo:claimId');
  localStorage.removeItem('yo:passengerList');
  localStorage.removeItem('yo:personalInfo');
  localStorage.removeItem('yo:claimAmount');
  localStorage.removeItem('yo:ticketRefundQuestionnaire');
  localStorage.removeItem('yo:legalGuardians');
  localStorage.removeItem('yo:minorPassengersId');
  localStorage.removeItem('token');
};

const CompensationCheck = () => {
  const { formatMessage } = useIntl();
  const [hasVisaPayment, setHasVisaPayment] = useState();
  const [customerFlights] = useGlobalState('customerFlights.booked');
  const [alternativeFlights] = useGlobalState('customerFlights.alternative');
  const [{ flightDisruptions } = {}] = useLocalStorageState(
    'flightDisruptions'
  );
  const [, setPlaceOfJurisdiction] = useLocalStorageState(
    'placeOfJurisdiction'
  );
  const [, setLettersToSign] = useLocalStorageState('lettersToSign');
  const translatedNavigate = useTranslatedNavigate();
  const [, setProductType] = useLocalStorageState(PRODUCT_TYPE);
  const [, setClaimAmount] = useLocalStorageState('claimAmount');
  const [, setShouldAuthenticate] = useLocalStorageState('shouldAuthenticate');
  const visaCardIssuer = localStorage.getItem('yo:visaCardIssuer');
  const [handleError] = useHandleError();

  const { data: visaCards, get: fetchCards } = useJsonApi('/visaCardTypes');

  const radioOptions = [
    {
      value: 'yes',
      label: messages.checkCompensationModalConfirm,
    },
    {
      value: 'no',
      label: messages.checkCompensationModalCancel,
    },
  ];

  const {
    data: visaBanks,
    loading: isBanksLoading,
    get: fetchBanks,
  } = useJsonApi('/organizations?filter[type]=bank');
  /**
   * Whenever users goes to this screen we want to reset this in case he will
   * change email and go back with browser buttons
   */
  useEffect(() => {
    setShouldAuthenticate(null);
    // eslint-disable-next-line
    if (
      !customerFlights ||
      !customerFlights?.length ||
      flightDisruptionMissing(flightDisruptions)
    ) {
      translatedNavigate('/');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (hasVisaPayment === 'yes') {
      fetchCards();
      fetchBanks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasVisaPayment]);

  function handleSubmit(values, { setSubmitting }) {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (hasVisaPayment === 'yes') {
      localStorage.setItem('yo:visa_card_type', values?.visa_card_type);
      if (visaCardIssuer) {
        const findBankOrganization = visaBanks.find(
          bank => bank?.name?.toLowerCase() === visaCardIssuer?.toLowerCase()
        );
        localStorage.setItem('yo:visaCardIssuer', findBankOrganization?.id);
      } else if (
        !visaCardIssuer &&
        values?.visaCardIssuer &&
        values?.visaCardIssuer !== 'other'
      ) {
        localStorage.setItem('yo:visaCardIssuer', values?.visaCardIssuer);
      }
    }
    // We have to reset all local storage here and log out the user
    clearPart2Data();
    localStorage.removeItem('isLoggedIn');
    setProductType(null);
    setShouldAuthenticate(null);
    let data = gatherFlightData({
      customerFlights,
      alternativeFlights,
      flightDisruptions,
      values,
    });
    createCart(data)
      .then(
        ({
          advice,
          should_authenticate,
          advice_reason,
          advice_description,
          place_of_jurisdiction,
          product_type,
          claim_amount,
          letters_to_sign,
          lawyer,
        }) => {
          setProductType(product_type);
          setClaimAmount(claim_amount);
          setPlaceOfJurisdiction(place_of_jurisdiction);
          setLettersToSign(letters_to_sign);

          if (advice === true) {
            if (lawyer?.id) {
              localStorage.setItem('yo:lawyerId', lawyer?.id);
            }
            if (isLoggedIn && should_authenticate === true) {
              setShouldAuthenticate(true);
              localStorage.setItem('isLoggedIn', 'true');
              translatedNavigate(['/', routes.congratulationsBasepath]);
            } else if (!isLoggedIn && should_authenticate === true) {
              setShouldAuthenticate(true);
              translatedNavigate(['/', routes.login], {
                state: { source: LoginSource.Funnel },
              });
            } else {
              setShouldAuthenticate(false);
              translatedNavigate(['/', routes.congratulationsBasepath]);
            }
          } else if (advice === false) {
            translatedNavigate(['/', routes.sorry], {
              state: {
                reason: advice_reason,
                description: advice_description,
              },
            });
          }
        }
      )
      .catch(error => {
        handleError(error);
        setSubmitting(false);
      });
  }

  function navigateBack() {
    if (alternativeFlights?.length > 0) {
      translatedNavigate(`alternative-flights/${alternativeFlights.length}`);
    } else if (shouldChooseAlternativeFlight(flightDisruptions)) {
      translatedNavigate(routes.alternativeFlight);
    } else translatedNavigate(routes.itinerary);
  }

  const handleVisaPayment = event => {
    // setHasVisaPayment()
    setHasVisaPayment(event);
  };

  const transformOptions = type => {
    if (type === 'cards') {
      const tranformVisaCards = visaCards?.map(card => ({
        value: card?.id,
        label: card?.id,
      }));
      const sortedVisaCards = tranformVisaCards?.sort((cardA, cardB) => {
        return cardA?.label?.split(' ')[1] > cardB?.label.split(' ')[1]
          ? 1
          : -1;
      });
      return sortedVisaCards;
    } else {
      const tranformVisaBanks = visaBanks?.map(bank => ({
        label: bank?.name,
        value: bank?.id,
      }));

      const otherOptions = {
        label: formatMessage(messages.otherOption),
        value: 'other',
      };

      const sortedVisaBanks = tranformVisaBanks?.sort((bankA, bankB) => {
        return bankA?.label > bankB?.label ? 1 : -1;
      });
      sortedVisaBanks.push(otherOptions);

      return sortedVisaBanks;
    }
  };

  return (
    <Formik
      initialValues={{ passengers: 1 }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ setFieldValue }) => {
        return (
          <Container>
            <StepObserver stepName={steps.CheckCompensation} />
            <Seo
              title={messages.seoTitle}
              description={messages.seoDescription}
            />
            <Main className="sm:mt-0">
              <div className="flex justify-end block sm:hidden">
                <InfoOverlay description={messages.asideText} />
              </div>
              <Form>
                <Card>
                  <DuplicateClaimAlert
                    formatMessage={formatMessage}
                    customerFlights={customerFlights}
                    navigate={translatedNavigate}
                  />
                  <fieldset>
                    <Count
                      name="passengers"
                      label={messages.paxHeader}
                      headerLabel
                      className="mb-50 sm:max-w-none"
                      icon={
                        <PaxIcon
                          viewBox="0 0 36 40"
                          height="20"
                          width="18"
                          className="fill-black"
                        />
                      }
                    />
                    <Input
                      name="email"
                      type="email"
                      label={messages.yourEmailAddress}
                      placeholder={messages.emailPlaceholder}
                      headerLabel
                    />
                    <div className="fieldset sm:flex-row mt-25 text-sm">
                      <Checkbox
                        wrapperClassName="sm:max-w-full"
                        name="newsletter"
                        label={messages.newsletterCheckbox}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    {config?.isVisaSite ? (
                      <>
                        <RadioGroup
                          headerLabel
                          row
                          border
                          name="visa"
                          options={radioOptions}
                          // eslint-disable-next-line react/jsx-no-bind
                          callback={handleVisaPayment}
                          hasLabel={true}
                          extraDescription={formatMessage(
                            messages.visaRadioLabel
                          )}
                          wrapperClassName={'sm:max-w-none mb-50'}
                        />
                        {hasVisaPayment === 'yes' &&
                        visaCards?.length &&
                        !isBanksLoading ? (
                          <>
                            <NativeSelect
                              headerLabel
                              className={'sm:w-full mb-30'}
                              name="visa_card_type"
                              placeholder={formatMessage(messages.placeholder)}
                              selectedPlaceholder={true}
                              // eslint-disable-next-line react/jsx-no-bind
                              options={transformOptions('cards')}
                              label={messages.visaCardsLabel}
                            />
                            {!visaCardIssuer ? (
                              <NativeSelect
                                headerLabel
                                className={'sm:w-full'}
                                name="visaCardIssuer"
                                // eslint-disable-next-line react/jsx-no-bind
                                options={transformOptions('banks')}
                                label={messages.visaBanksLabel}
                                placeholder={formatMessage(
                                  messages.placeholder
                                )}
                                selectedPlaceholder={true}
                              />
                            ) : null}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </fieldset>
                </Card>
                <StepNavigation
                  onBackClick={navigateBack}
                  backButtonMessage={formatMessage(messages.submitBack)}
                  submitButtonMessage={formatMessage(messages.submitClaim)}
                  dataLayerPushOnBack
                  dataLayerPushOnContinue
                  step={steps.CheckCompensation}
                />
                <FormikPersist localStorageKey={'compensationCheck'} />
              </Form>
            </Main>
            <Aside showSocialProof>
              <SocialProof>
                <Icon src={moneyIcon} className="mb-20" />
                {formatMessage(messages.asideText)}
              </SocialProof>
            </Aside>
            <TrustBox />
            <SocialProofMobile />
          </Container>
        );
      }}
    </Formik>
  );
};

export default CompensationCheck;
