import React from 'react';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

import DatePicker from '../../components/fields/DatePicker/index';
import Input from '../../components/fields/Input';
import messages from '../messages/searchFlightForm';
import ResultsList from './ResultsList';

import {
  validateFlightDate,
  validateFlightCode,
} from '../../utils/yupValidators';
import { FlightSearchFormProps } from './index';
import FormikPersist from '../../components/fields/FormikPersist';
import StepNavigation from '../../components/StepNavigation';
import { isEmpty } from 'lodash';

interface SearchByCodeProps extends FlightSearchFormProps {
  hideFlightCodeField: () => void;
  showFlightCodeField: () => void;
  connectionHasSelected: boolean;
  isFirstPage: boolean;
  showManualFlightForm: () => void;
}

const validationSchema = yup.object().shape({
  flight_code: validateFlightCode.required(),
  departure_date: validateFlightDate.required(),
});

const SearchByCode = ({
  hideFlightCodeField,
  navigateNext,
  navigateBack,
  flightIndex,
  connectionHasSelected = false,
  isFirstPage = false,
  initialValues,
  showManualFlightForm,
  ...props
}: SearchByCodeProps) => {
  const { formatMessage } = useIntl();

  return (
    <Formik
      initialValues={initialValues || {}}
      onSubmit={navigateNext}
      isInitialValid={!isEmpty(initialValues) ? true : false}
      validationSchema={validationSchema}
      initialErrors={{}}
      validateOnMount={!isEmpty(initialValues) ? true : false}
    >
      {({ values, isValid }) => (
        <Form>
          <div className="fieldset sm:flex-row sm:mt-25">
            <Input
              name="flight_code"
              label={messages.flightNumberLabel}
              placeholder={messages.flightCodePlaceholder}
            />
            <DatePicker
              name="departure_date"
              label={messages.flightDateLabel}
              autoComplete="off"
            />
          </div>
          <p>
            <a className="link" onClick={hideFlightCodeField}>
              {formatMessage(messages.iDontKnowFlightNumber)}
            </a>
          </p>
          {isValid &&
          ((connectionHasSelected && isFirstPage) || !isFirstPage) ? (
            <ResultsList
              {...props}
              flightIndex={flightIndex}
              isFlightCodeShown={true}
              showManualFlightForm={showManualFlightForm}
              values={values}
            />
          ) : isValid && !connectionHasSelected ? (
            <p style={{ color: 'red' }}>
              {formatMessage(messages.selectConnectionType)}
            </p>
          ) : null}
          <FormikPersist
            initialValues={initialValues}
            localStorageKey={`${props.step}-search-by-code`}
            favorInitialValues={!isEmpty(initialValues) ? true : false}
          />
          <StepNavigation
            onBackClick={navigateBack}
            step={`${props.step}-search-by-code`}
            portalId="delegateSubmit"
            dataLayerPushOnBack={true}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SearchByCode;
