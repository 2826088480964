import React from 'react';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

import AirportSelect, {
  airportSelectMessages,
} from '../../components/fields/AirportSelect/index';
import AirlineSelect from '../../components/fields/AirlineSelect/index';
import DatePicker from '../../components/fields/DatePicker/index';

import DepartureIcon from '../../assets/icons/departure-icon.inline.svg';
import ArrivalIcon from '../../assets/icons/arrival-icon.inline.svg';
import messages from '../messages/searchFlightForm';

import { validateDate, validateFlightCode } from '../../utils/yupValidators';

import FormikPersist from '../../components/fields/FormikPersist';
import Input from '../../components/fields/Input';
import StepNavigation from '../../components/StepNavigation';
import { useGlobalState } from '../../services/GlobalStore/GlobalStore';
import { setCustomerFlight } from '../../services/GlobalStore/reducer';
import { FlightSearchFormProps } from './index';

interface EnterFlightManuallyProps extends FlightSearchFormProps {
  navigateNext: () => void;
}

const validationSchema = yup.object().shape({
  departure_airport_id: yup
    .string()
    .nullable()
    .required(),
  arrival_airport_id: yup
    .string()
    .nullable()
    .required(),
  airline_id: yup
    .string()
    .nullable()
    .required(),
  flight_date: validateDate.required(),
  flight_code: validateFlightCode.required(),
});

interface ManualFlightValues {
  airline_id: string;
  departure_airport_id: string;
  arrival_airport_id: string;
  flight_code: string;
  flight_date: string;
}

const EnterFlightManually = ({
  navigateNext,
  navigateBack,
  step,
  flightIndex,
  flightType,
}: EnterFlightManuallyProps) => {
  const { formatMessage } = useIntl();

  const [, dispatch] = useGlobalState('customerFlights.booked');
  const [airports] = useGlobalState('airports');
  const [airlines] = useGlobalState('airlines');

  function handleSubmit(values: ManualFlightValues) {
    const airline_code = airlines[values.airline_id];
    const departure_airport = airports[values.departure_airport_id];
    const arrival_airport = airports[values.arrival_airport_id];
    dispatch(
      setCustomerFlight(
        {
          ...values,
          airline_code: airline_code.iata || airline_code.icao,
          departure_airport: departure_airport.iata || departure_airport.icao,
          arrival_airport: arrival_airport.iata || arrival_airport.icao,
          type: flightType,
        },
        flightIndex,
        flightType
      )
    ).then(() => {
      navigateNext();
    });
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={handleSubmit}
      isInitialValid={false}
      validationSchema={validationSchema}
      initialErrors={{}}
    >
      <Form>
        <div className="fieldset sm:flex-row">
          <AirportSelect
            name="departure_airport_id"
            label={airportSelectMessages.labelDepartureAirport}
            placeholder={airportSelectMessages.placeholderDepartureAirport}
            icon={<DepartureIcon />}
            autoComplete="off"
          />
          <AirportSelect
            name="arrival_airport_id"
            label={airportSelectMessages.labelArrivalAirport}
            placeholder={airportSelectMessages.placeholderArrivalAirport}
            icon={<ArrivalIcon />}
            autoComplete="off"
          />
        </div>
        <div className="fieldset sm:flex-row">
          <AirlineSelect
            name="airline_id"
            autoComplete="off"
            className="sm:min-w-315 flex-grow flex-shrink-0"
          />
          <Input
            name="flight_code"
            className="sm:min-w-140 flex-shrink"
            label={messages.flightNumberLabel}
            placeholder={messages.flightCodePlaceholder}
          />
          <DatePicker
            name="flight_date"
            className="sm:min-w-140 flex-shrink"
            label={messages.flightDateLabel}
            autoComplete="off"
          />
        </div>
        <FormikPersist localStorageKey={`${step}-manually`} />
        <StepNavigation
          onBackClick={navigateBack}
          submitButtonMessage={formatMessage(messages.continue)}
          backButtonMessage={formatMessage(messages.back)}
          step={`${step}-manual-flight`}
          portalId="delegateSubmit"
          dataLayerPushOnBack={true}
          dataLayerPushOnContinue={true}
        />
      </Form>
    </Formik>
  );
};

export default EnterFlightManually;
